import { Image, Notes } from '@mui/icons-material'
import { T, useTranslate } from '@tolgee/react'
import axios from 'axios'
import classNames from 'classnames'
import { ViewContext, useAPI } from 'components/lib'
import { CircleNotch } from 'phosphor-react'
import { useContext, useState } from 'react'
import { Button, Microbutton } from 'ui'
import moment from 'moment'
import { useStripe } from '@stripe/react-stripe-js'
import parse from 'html-react-parser'
import { Message } from 'components/lib'
import { successToast, errorToast } from 'helpers'

export default function YourPlan({ subscription, plans }) {
  const stripe = useStripe()
  const { data, loading } = useAPI('api/account/usage')
  const context = useContext(ViewContext)
  const [isLoading, setIsLoading] = useState(false)
  const [isCancelRenewalLoading, setIsCancelRenewalLoading] = useState(false)
  const [sendMailActive, setSendMailActive] = useState(true)
  const { t } = useTranslate()

  if (loading || !data) return <div className='h-[1137px] border-b'></div>
  const activePlan = plans?.raw.plans.find((plan) => plan?.id === plans.active.id)

  const priceDiff = activePlan.pricing.month.price * 12 - activePlan.pricing.year.price

  const wordLimitText = t('eleo-profile-words-left', {
    wordsLeft: Math.max(data.wordsLeft + data.bonusWordsLeft, 0),
  })

  const wordAdditionalText = `${t('eleo-profile-includes', 'includes')} ${Math.max(
    data.bonusWordsLeft,
    0
  )} ${t('eleo-profile-none-expiring', 'non-expiring')}`
  const imageLimitText = t('eleo-profile-images-left', {
    imagesLeft: Math.max(data.imagesLeft + data.bonusImagesLeft, 0),
  })
  const imageAdditionalText = `${t('eleo-profile-includes', 'includes')} ${Math.max(
    data.bonusImagesLeft,
    0
  )} ${t('eleo-profile-none-expiring', 'non-expiring')}`

  const futurePlan = plans?.raw?.plans.find(
    (plan) =>
      plan.pricing.month.id === subscription.object.future_price ||
      plan.pricing.year.id === subscription.object.future_price
  )

  function getPlanDescription({
    hasCoupon,
    futurePrice,
    expiring,
    expiresOn,
    plan,
    period,
    trialEnd,
  }) {
    console.log(trialEnd)
    if (hasCoupon) {
      if (futurePrice) {
        return parse(
          t('eleo-profile-description-coupon-with-upgrade', {
            expiresOn: moment(expiresOn).format('DD.MM.YYYY'),
            plan,
            futurePrice,
          })
        )
      } else
        return parse(
          t('eleo-profile-description-coupon', {
            expiresOn: moment(expiresOn).format('DD.MM.YYYY'),
            plan,
          })
        )
    }
    if (trialEnd) {
      if (futurePrice && futurePrice !== 'Free')
        return parse(
          t('eleo-profile-description-trial-changing', {
            trialEnd: moment(trialEnd).format('DD.MM.YYYY'),
            plan,
            futurePrice,
          })
        )

      if (expiring)
        return parse(
          t('eleo-profile-description-trial-expiring', {
            trialEnd: moment(trialEnd).format('DD.MM.YYYY'),
            plan,
          })
        )

      if (expiresOn)
        return parse(
          t('eleo-profile-description-trial', {
            trialEnd: moment(trialEnd).format('DD.MM.YYYY'),
            plan,
            period,
          })
        )
    }

    if (futurePrice && futurePrice !== 'Free') {
      return parse(
        t('eleo-profile-description-changing', {
          expiresOn: moment(expiresOn).format('DD.MM.YYYY'),
          plan,
          futurePrice,
        })
      )
    }
    if (expiring) {
      return parse(
        t('eleo-profile-description-expiring', {
          expiresOn: moment(expiresOn).format('DD.MM.YYYY'),
          plan,
        })
      )
    }
    if (expiresOn) {
      return parse(
        t('eleo-profile-description-refreshing', {
          refreshesOn: moment(expiresOn).format('DD.MM.YYYY'),
          plan,
          period,
        })
      )
    }
    return parse(
      t('eleo-profile-description-non-expiring', {
        plan,
      })
    )
  }

  // Shows when the user has a monthly plan and doesnt have a downgrade scheduled
  const showAnnualButton =
    subscription.object?.plan?.id !== 'free' &&
    subscription.object?.period === 'month' &&
    !subscription.object?.future_price

  async function switchToAnnual() {
    try {
      setIsLoading(true)
      const url = '/api/account/plan'
      let data = {
        plan: plans.active.id,
        period: 'year',
      }
      const res = await axios.patch(url, data)

      if (res.data.requires_payment_action) {
        const stripeRes = await stripe.handleCardPayment(res.data.client_secret)

        if (stripeRes.error) {
          context.handleError(stripeRes.error.message)
          setIsLoading(false)
          return false
        } else {
          // re-send the form
          data.stripe = res.data
          await axios.patch(url, data)
        }
      }

      window.location.reload()
    } catch (err) {
      context.handleError(err)
    } finally {
      setIsLoading(false)
    }
  }

  async function cancelRenewal(planId) {
    setIsCancelRenewalLoading(true)
    try {
      const url = '/api/account/plan'
      const data = {
        plan: 'free',
        hasCoupon: plans.active.hasCoupon,
        ...(plans.active.hasCoupon && { start_date: new Date(plans.active.codeExpires).getTime() }),
      }

      await axios.patch(url, data)

      window.location.reload()
    } catch (err) {
      console.log(err)
      context.handleError(err)
    } finally {
      setIsCancelRenewalLoading(false)
    }
  }

  async function resendPaymentEmail() {
    try {
      setSendMailActive(false)
      await axios.post('/api/account/payments/mail')
      successToast(t('eleo-payment-email-success', 'Payment email sent'))
      setTimeout(() => setSendMailActive(true), 5000)
    } catch (err) {
      context.handleError(err)
      errorToast(
        t(
          'eleo-payment-email-fail',
          'Failed to send the payment email. Please try again or contact support'
        ),
        null,
        10000
      )
    }
  }
  const formatter = new Intl.NumberFormat('en', { notation: 'compact' })

  return (
    <div className='mx-auto max-w-[1258px] space-y-[30px] border-b px-[30px] py-[30px]'>
      {/* Your plan section */}
      <div className='flex flex-col gap-[10px]'>
        <h2 className='mb-[10px] text-[18px] font-semibold'>
          <T keyName='eleo-profile-your-plan'>Your current plan</T>
        </h2>
        <div className='text-[15px]'>
          {getPlanDescription({
            hasCoupon: plans.active.hasCoupon,
            expiring: subscription?.object?.cancel_at_period_end,
            expiresOn: subscription.object?.current_period_end,
            period: subscription.object.period,
            plan: subscription.object?.plan?.name,
            futurePrice: futurePlan?.name,
            trialEnd: subscription.object?.trial_end,
          })}
          <span className='text-brand-violet font-medium'>
            <T
              keyName='eleo-with-renewal-on'
              defaultValue=' with a renewal on {renewalDate}.'
              params={{ renewalDate: moment(subscription.renewal).format('DD.MM.YYYY') }}
            />
          </span>
        </div>

        <div
          className='grid gap-1 md:items-center'
          style={{
            gridTemplateColumns:
              'min-content max-content min-content max-content min-content max-content',
          }}
        >
          <Notes className='text-brand-violet mr-1 text-[18px]' fontSize='small' />
          <div className='col-span-5 grid-cols-subgrid md:grid'>
            <div className='body-secondary text-brand-gray-dark'>{wordLimitText}</div>
            <div className='body-secondary mx-[10px] hidden opacity-30 md:block'>|</div>

            <div className='body-secondary text-brand-secondary'>
              <T
                keyName='eleo-word-renewal'
                defaultValue='{limit} limit renewing on {renewalDate}'
                params={{
                  limit: formatter.format(
                    plans?.raw?.plans.find((plan) => plan.id === subscription?.object?.plan?.id)
                      ?.word_limit
                  ),
                  renewalDate: moment(subscription.renewal).format('DD.MM.YYYY'),
                }}
              />
            </div>

            {data.bonusWordsLeft > 0 ? (
              <>
                <div className='body-secondary mx-[10px] hidden opacity-30 md:block'>|</div>
                <div className='text-brand-secondary body-secondary'>{wordAdditionalText}</div>
              </>
            ) : (
              <>
                <div />
                <div />
              </>
            )}
          </div>

          <Image className='text-brand-violet mr-1 text-[18px]' fontSize='small' />

          <div className='col-span-5 grid-cols-subgrid md:grid'>
            <div className='body-secondary text-brand-gray-dark'>{imageLimitText}</div>
            <div className='body-secondary mx-[10px] hidden opacity-30 md:block'>|</div>

            <div className='body-secondary text-brand-secondary'>
              <T
                keyName='eleo-word-renewal'
                defaultValue='{limit} limit renewing on {renewalDate}'
                params={{
                  limit: plans?.raw?.plans.find(
                    (plan) => plan.id === subscription?.object?.plan?.id
                  )?.image_limit,
                  renewalDate: moment(subscription.renewal).format('DD.MM.YYYY'),
                }}
              />
            </div>

            {data.bonusImagesLeft > 0 ? (
              <>
                <div className='body-secondary mx-[10px] hidden opacity-30 md:block'>|</div>
                <div className='text-brand-secondary body-secondary'>{imageAdditionalText}</div>
              </>
            ) : (
              <>
                <div />
                <div />
              </>
            )}
          </div>
        </div>
      </div>

      {subscription?.status !== 'active' && subscription?.status !== 'trialing' ? (
        <Message
          className='!mb-0 !mt-4 xl:!m-0 xl:!w-1/3'
          type='warning'
          title={<T keyName='eleo-payment-past-due'>Payment past due</T>}
        >
          <T keyName='eleo-message-payment-past-due'>
            Please check you email to authorize the transaction or downgrade to the free plan down
            below
          </T>
          <br />
          <Button
            className='mx-auto mt-2 '
            disabled={!sendMailActive}
            onClick={resendPaymentEmail}
            variant='small'
            color='yellow'
          >
            <T keyName='eleo-resend-payment-email-button'>Resend email</T>
          </Button>
        </Message>
      ) : (
        <div className='justify-between sm:flex'>
          {showAnnualButton ? (
            <Microbutton
              onClick={switchToAnnual}
              isLoading={isLoading}
              className='h-[30px] min-w-40'
              variant='solid'
              text={t('eleo-profile-plan-switch-plan', 'Switch to annual and Save $') + priceDiff}
            />
          ) : (
            <div />
          )}
          {!subscription.object.cancel_at_period_end && (
            <Microbutton
              onClick={cancelRenewal}
              isLoading={isCancelRenewalLoading}
              className='h-[30px] min-w-40'
              variant='transparent-light'
              text={t('eleo-profile-cancel-renewal', 'Cancel the renewal')}
            />
          )}
        </div>
      )}
    </div>
  )
}
