import { Close } from '@mui/icons-material'
import { T, useTranslate } from '@tolgee/react'
import classNames from 'classnames'
import React from 'react'
import { Microbutton } from 'ui'

const ChatbotsCarouselHeader = ({
  isSearchActive,
  icon,
  title,
  searchHintsUI,
  clearSearch,
  disableDivider = false,
  isExpanded,
  setIsExpanded,
}) => {
  const { t } = useTranslate()
  return (
    <div
      className={classNames(
        'chat-library-header group flex flex-wrap items-center  gap-[4px]  rounded-[4px] py-[11px] pl-[16px] lg:flex-nowrap  lg:gap-[10px]',
        isSearchActive ? 'w-full' : 'max-w-[600px]'
      )}
    >
      <h2
        className={classNames(
          'text-brand-violet-tertiary  flex   flex-nowrap items-center gap-[16px] rounded-[4px]  text-[14px] font-medium  leading-[1.1em]',
          isSearchActive ? 'first-letter:uppercase' : 'uppercase'
        )}
      >
        {icon}
        {title}
      </h2>

      {isSearchActive && (
        <>
          {!disableDivider && <div className='bg-brand-violet-tertiary  h-[17px] w-[2px] ' />}
          <div>{searchHintsUI}</div>
          <div className='hidden lg:block'>
            <Microbutton
              type='main'
              onClick={() => clearSearch()}
              iconPlacement='left'
              icon={
                <Close
                  className='text-brand-violet-secondary'
                  sx={{ height: '18px', width: '18px' }}
                />
              }
              text={t('eleo-chatbot-library-clear-search', 'Clear search')}
              className='!bg-brand-violet-semi_transparent !px-[4px] !py-[5px]'
              hoverClasses='hover:!bg-brand-violet-semi_transparent/20 transition-all duration-300 opacity-0  group-hover:opacity-100'
              textClasses='!text-[12px] !font-medium !text-brand-violet-secondary'
            />
          </div>
        </>
      )}

      {setIsExpanded && (
        <Microbutton
          type='main'
          text={
            isExpanded ? (
              <T keyName='eleo-collapse'>Collapse</T>
            ) : (
              <T keyName='eleo-expand'>Expand</T>
            )
          }
          className='!bg-brand-violet-semi_transparent !px-[4px] !py-[5px]'
          hoverClasses='hover:!bg-brand-violet-semi_transparent/20 transition-all duration-300 opacity-0  group-hover:opacity-100'
          textClasses='!text-[12px] !font-medium !text-brand-violet-secondary'
          onClick={() => setIsExpanded((prev) => !prev)}
        />
      )}
    </div>
  )
}

export default ChatbotsCarouselHeader
