import { errorToast, isValidUrl } from 'helpers'
import { T } from '@tolgee/react'
import axios from 'axios'
const Settings = require('settings.json')

export function compareChatbotConfigs(obj1, obj2, keysToSkip = [], currentPath = '') {
  let res = true
  const differences = []

  if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
    return [obj1 === obj2, differences]
  }

  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    if (obj1.length !== obj2.length) {
      return [false, ['array']]
    }
    for (let i = 0; i < obj1.length; i++) {
      const isEqual = compareChatbotConfigs(obj1[i], obj2[i], keysToSkip, `${currentPath}[${i}]`)[0]
      if (!isEqual) return [false, ['array']]
    }
    return [true, differences]
  }

  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)

  const commonKeys = keys1.filter((key) => keys2.includes(key))
  for (let key of commonKeys) {
    const newPath = currentPath ? `${currentPath}.${key}` : key

    // Check if the current path or any parent path is in keysToSkip
    const shouldSkip = keysToSkip.some((skipKey) => {
      // Exact match
      if (skipKey === newPath) return true
      // Parent path match (e.g., 'parent' should match 'parent.child')
      if (newPath.startsWith(skipKey + '.')) return true
      return false
    })

    if (shouldSkip || key === 'author') continue

    const [isEqual, childDifferences] = compareChatbotConfigs(
      obj1[key],
      obj2[key],
      keysToSkip,
      newPath
    )
    if (!isEqual) {
      res = false
      differences.push([newPath, ...childDifferences])
    }
  }

  return [res, differences]
}

export const generateWebsiteCode = (chatSettings, id) => {
  return `
    <!-- Insert below code after body tag -->
    <div 
      id="eleo-chat-bot" 
      position="${chatSettings.chatPlacement}" 
      chat-bot-name="${id}" 
      chat-window-height="${chatSettings.windowHeight}"
      chat-window-width="${chatSettings.windowWidth}"
      widget="true"></div>

    <!-- Insert below code at the end of body tag -->
    <script
      type="text/javascript"
      defer
      src="${renderChatBotURL()}/chat-bot-widget.js">
    </script>`
}

const renderChatBotURL = () => {
  if (process.env.REACT_APP_MODE === 'develop' || process.env.NODE_ENV === 'development') {
    return `https://develop--chat-bot-widget.netlify.app`
  }

  if (process.env.NODE_ENV === 'production') {
    return `https://chatbot.eleo.ai`
  }
}

export const transformLinkToOrigin = (link) => {
  if (link && isValidUrl(link)) {
    const origin = new URL(link).origin
    return origin
  } else {
    return null
  }
}

const detectLocalAPIendPoint = () => {
  const currentProcess = process.env.REACT_APP_MODE
    ? process.env.REACT_APP_MODE
    : process.env.NODE_ENV

  //return url generated by NGROK on development  because telegram api works only https protocol, it not works for localhost
  if (currentProcess === 'development') {
    return `https://moth-settling-unlikely.ngrok-free.app` //POINT YOUR NGROK URL
  } else {
    return Settings[currentProcess].server_url
  }
}

export const handleTelegramWebHook = async (
  botToken,
  telegramBotStatus,
  action = 'setWebhook',
  pendingUpdates = false,
  botId
) => {
  const TELEGRAM_API_URL = `https://api.telegram.org/bot${botToken}`
  const URL = `${TELEGRAM_API_URL}/${action}?url=${detectLocalAPIendPoint()}/telegram/webhook${botId ? `/${botId}` : ''}&drop_pending_updates=${pendingUpdates}`
  try {
    await axios.post(URL)

    if (action !== 'setWebhook') return

    // if (response.data?.ok) {
    //   handleChangeTelegramBotStatus(telegramBotStatus)
    // }
    const res = await axios.post(`${TELEGRAM_API_URL}/getMe`)
    const botData = res.data.result

    return botData.username
  } catch (err) {
    console.log(err)
    errorToast(
      <T keyName='eleo-telegram-bot-error'>
        Unexpected error occurred while deploying the telegram bot
      </T>
    )
  }
}

export const CHATBOT_VISIBILITY_OPTIONS = [
  { label: <T keyName='eleo-chatbot-visibility-public'>Everyone</T>, value: 'public' },
  { label: <T keyName='eleo-chatbot-visibility-private'>Only me</T>, value: 'private' },
  { label: <T keyName='eleo-chatbot-visibility-groups'>Selected groups</T>, value: 'groups' },
]

export const ignoredFieldPerType = {
  global: ['inputValues.wordsConsumed'],
  forum: [
    'inputValues.monthlyLimit',
    'inputValues.isMonthlyLimit',
    'inputValues.customUrls.public',
    'inputValues.wordsConsumed',
    'settings',
  ],
  public: [
    'inputValues.forum',
    'inputValues.relatedGroups',
    'inputValues.visibilityOption',
    'inputValues.customUrls.forum',
    'inputValues.wordsConsumed',
    'settings',
  ],
  embed: [
    'settings.telegramBotActive',
    'settings.telegramToken',
    'settings.activeOnEleo',
    'settings.publishing',
    'inputValues.forum',
    'inputValues.relatedGroups',
    'inputValues.visibilityOption',
    'inputValues.customUrls.forum',
    'inputValues.wordsConsumed',
    'inputValues.customUrls.public',
  ],
  telegram: [
    'settings.activeOnEleo',
    'settings.publishing',
    'inputValues.forum',
    'inputValues.relatedGroups',
    'inputValues.visibilityOption',
    'inputValues.customUrls.forum',
    'inputValues.wordsConsumed',
    'inputValues.customUrls.public',
    'settings.websites',
    'settings.windowWidth',
    'settings.windowHeight',
    'settings.chatPlacement',
  ],
}
