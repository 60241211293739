import { Favorite, Search } from '@mui/icons-material'
import { useTranslate } from '@tolgee/react'
import axios from 'axios'
import { ViewContext } from 'components/lib'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { ChatbotsCarousel } from '../ChatbotsCarousel'
import Divider from '../Divider'
import SectionSkeletonLoader from './SectionSkeletonLoader'
export const Liked = ({
  filter,
  signal,
  refetchData,
  customTitle,
  isSearchActive = false,
  clearSearch,
  searchHintsUI,
  disableSlider = false,
  returnHeader = false,
}) => {
  const context = useContext(ViewContext)
  const [items, setItems] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const { t } = useTranslate()
  const params = useSearchParams()[0]
  const category = params.get('category')
  const [numberPages, setNumberPages] = useState(1)
  const [totalChatbots, setTotalChatbots] = useState(0)

  const fetchItems = useCallback(async () => {
    try {
      setIsLoading(true)
      const res = await axios.get(
        `/api/chat-bot/library/liked?page=${numberPages}&pageSize=10&filter=${filter}${category ? `&category=${category}` : ''}`
      )
      if (numberPages === 1) setItems(res.data?.chatBots)
      else setItems((prev) => [...prev, ...res.data?.chatBots])
      setTotalChatbots(res.data?.total)
    } catch (err) {
      context.handleError(err)
    } finally {
      setIsLoading(false)
    }
  }, [filter, category, numberPages])

  useEffect(() => {
    fetchItems()
  }, [fetchItems, signal])

  if (isLoading && !items?.length) return <SectionSkeletonLoader />
  if (!items?.length) return

  const title = customTitle || t('liked-chatbots-title', 'YOUR FAVORITES')

  return (
    <div className='bg-[#f5f5fa]'>
      <ChatbotsCarousel
        items={items}
        title={title}
        total={totalChatbots}
        clearSearch={clearSearch}
        isSearchActive={isSearchActive}
        searchHintsUI={searchHintsUI}
        icon={
          isSearchActive ? (
            <Search sx={{ height: '18px', width: '18px' }} />
          ) : (
            <Favorite sx={{ height: '18px', width: '18px' }} />
          )
        }
        refetchData={refetchData}
        disableSlider={disableSlider}
        seeMoreUrl='/chatbot/library/liked'
        setNumberPages={setNumberPages}
      />
      <Divider />
    </div>
  )
}

export default Liked
