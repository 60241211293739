import { T } from '@tolgee/react'
import classNames from 'classnames'
import { ContextMenu } from 'components/context-menu'
import { SocialShare, useNavigate, ViewContext } from 'components/lib'
import { detectRtlDirection } from 'helpers'
import { CopyToClipBoard } from 'helpers/clipboard'
import eleoLogo from 'images/new-logos/logo-oval.png'
import { useContext, useRef, useState } from 'react'
import * as Selection from 'selection-popover'
import defaultAvatar from '../../../images/avatars/default_avatar.svg'
import { MessageContextItem } from '../MessageContext/item'
import Markdown from './Markdown'
import { MessageLoader } from './MessageLoader'
import { MessageOptions } from './MessageOptions'
import { MessageContextItemBroken } from '../MessageContext/context-item-broken'

export default function ChatMessage({
  message,
  customAssistantLogo,
  widget = false,
  language,
  regenerateResponse,
  index,
  avatar,
  handleTTS,
  width,
  ttsIndex,
  setImagePreviewData,
}) {
  const context = useContext(ViewContext)
  const contentRef = useRef()
  const [selectedText, setSelectedText] = useState('')
  const changeWritingDirection = detectRtlDirection(language)
  const navigate = useNavigate()

  if (message.role === 'system') return

  const isLoading =
    message.content === 'STATE_LOADING' || message.content === 'STATE_LOADING_CONTEXT'

  const handleSelectionChange = () => {
    const selection = window.getSelection()
    if (selection && selection.toString()) {
      // Text is selected within the container
      setSelectedText(selection.toString())
    }
  }

  const showShareModal = (content) => {
    context.modal.show({
      children: (
        <div>
          <p className='text-brand-body !mb-[8px] text-center font-bold'>
            <T keyName='eleo-share-content-modal-text'>Share content in Social Media</T>
          </p>
          <div className='flex h-[40px] justify-center'>
            <SocialShare
              className='flex w-full justify-center'
              description={encodeURI(content)}
              url={window.location.origin}
            />
          </div>
        </div>
      ),
      modalCardClassNames: '!bg-brand-grey !px-[30px] !py-[20px]',
    })
  }

  return (
    <div
      className={classNames('relative min-w-0 first:mt-auto', {
        'items-end justify-end sm:items-start': message.role === 'user' && !widget,
        'flex gap-[5px]': widget,
        'flex flex-col sm:flex-row sm:items-end': !widget,
        '!flex-row-reverse': widget && message.role === 'user',
      })}
    >
      {/* Sender icon */}
      <div
        className={classNames(
          'flex items-center justify-center rounded-full sm:mb-0',
          // !customAssistantLogo && message.role !== 'user' && 'bg-yellow-400',
          message.role === 'user' ? '-right-[62px]' : '-left-[62px]',
          'bottom-0 mb-2 size-[42px] min-h-[42px] min-w-[42px] sm:absolute sm:mb-0'
        )}
      >
        <img
          className={classNames(
            'rounded-full object-contain',
            message.role === 'user' ? 'size-[42px] ' : 'size-[45px]',
            {
              '!size-[42px] !object-cover': widget && customAssistantLogo,
            }
          )}
          src={
            message.role === 'user'
              ? (avatar ?? defaultAvatar)
              : customAssistantLogo
                ? customAssistantLogo
                : eleoLogo
          }
          alt='eleo logo'
        />
      </div>

      <Selection.Root>
        <Selection.Trigger className='h-full min-w-0'>
          <div
            className={classNames(
              'group relative rounded-[10px] p-[30px] pb-[10px]',
              {
                'bg-transparent pt-[20px]': message.role === 'user',
                'border-brand-grey bg-brand-grey-grey-bg border': message.role !== 'user',
                'rtl-text': changeWritingDirection,
                '!pr-[5px]': widget && message.role === 'user',
              },
              'message'
            )}
            onMouseUp={() => handleSelectionChange()}
            dir={changeWritingDirection ? 'rtl' : 'ltr'}
          >
            {/* Markdown formatter wrapped in useMemo to avoid rerenders */}
            {isLoading ? (
              <MessageLoader usesContext={message.content === 'STATE_LOADING_CONTEXT'} />
            ) : message.role === 'assistant' ? (
              <div ref={contentRef}>
                <Markdown content={message.content} />
                {message.image && (
                  <>
                    <img
                      src={message.image}
                      alt=''
                      className='mb-2 max-h-[512px] cursor-pointer rounded-[4px]'
                      onClick={() =>
                        setImagePreviewData?.({
                          target: message.image,
                          isMobile: false,
                          isVisible: true,
                        })
                      }
                    />
                    <T
                      keyName='eleo-image-generation-link'
                      defaultValue='You can generate images in the <a>Images tab</a>'
                      params={{
                        a: (
                          // eslint-disable-next-line jsx-a11y/anchor-has-content
                          <span
                            className='text-brand-violet cursor-pointer'
                            href='/image'
                            alt='Image generation page'
                            onClick={() => navigate('/image')}
                          ></span>
                        ),
                      }}
                    />
                  </>
                )}
              </div>
            ) : (
              <div
                className={classNames(
                  'whitespace-break-spaces break-words'
                  // message.role === 'user' && 'text-end'
                )}
              >
                {message.content}
              </div>
            )}

            {message.role === 'user' ? (
              message.context?.length && message?.attachedFileInfo?.length ? (
                <div className='flex select-none flex-wrap items-center justify-end gap-[6px] p-[10px] pr-0'>
                  {message.context?.map((item) => (
                    <MessageContextItem
                      key={item.id}
                      item={item}
                      messageContext={message.context}
                      isEditable={false}
                      setImagePreviewData={setImagePreviewData}
                    />
                  ))}
                </div>
              ) : (
                <>
                  {message?.attachedFileInfo?.length ? (
                    <div className='flex select-none flex-wrap items-center justify-end gap-[6px] p-[10px] pr-0'>
                      {message?.attachedFileInfo?.map((item, i) => (
                        <MessageContextItemBroken key={i} item={item} />
                      ))}
                    </div>
                  ) : null}
                </>
              )
            ) : (
              <MessageOptions
                regenerateResponse={regenerateResponse}
                index={index}
                message={message}
                showShareModal={showShareModal}
                widget={widget}
                handleTTS={handleTTS}
                width={width}
                isLoading={isLoading}
                ttsIndex={ttsIndex}
                handleCopy={() =>
                  CopyToClipBoard(
                    contentRef.current?.textContent,
                    <T keyName='eleo-message-copied-successfully'>Message copied successfully!</T>,
                    contentRef.current?.innerHTML
                  )
                }
              />
            )}
          </div>
        </Selection.Trigger>

        <Selection.Portal>
          <Selection.Content
            side='bottom'
            align='start'
            collisionPadding={{ bottom: 50 }}
            className='z-[9999]'
          >
            <ContextMenu selectedText={selectedText} enableEdit={false} />
          </Selection.Content>
        </Selection.Portal>
      </Selection.Root>
    </div>
  )
}
