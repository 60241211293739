import React, { useCallback, useContext, useEffect, useState } from 'react'
import ChatbotsCarouselHeader from '../ChatbotsCarouselHeader'
import SearchHintsUI from '../SearchHintsUI'
import { Close, Search } from '@mui/icons-material'
import { useSearchParams } from 'react-router-dom'
import { ViewContext } from 'components/lib'
import axios from 'axios'
import LibraryItem from '../LibraryItem'
import SectionSkeletonLoader from './SectionSkeletonLoader'
import { T } from '@tolgee/react'
import { useChatbotCategories } from 'components/hooks/chatbotCategories'
import { Microbutton } from 'ui'

const SearchResults = ({ isSearchActive, setFilter, debounceFilter }) => {
  const params = useSearchParams()[0]
  const context = useContext(ViewContext)
  const { chatbotCategories } = useChatbotCategories()

  const [isLoading, setIsLoading] = useState(false)
  const [items, setItems] = useState([])

  const selectedCategory = params.get('category') || 'eleo-chat-library-all-categories'

  const clearSearch = () => {
    setFilter('')
  }

  const fetchItems = useCallback(async () => {
    const category = selectedCategory === 'eleo-chat-library-all-categories' ? '' : selectedCategory
    setIsLoading(true)
    try {
      const res = await axios.get(
        `/api/chat-bot/library?page=1&pageSize=200&category=${category}&filter=${debounceFilter}`
      )
      setItems(res.data.chatBots)
    } catch (err) {
      context.handleError(err)
    } finally {
      setIsLoading(false)
    }
  }, [selectedCategory, debounceFilter])

  const handleLikeChatbot = async (id) => {
    try {
      const url = items.find((item) => item.id === id)?.isLiked
        ? '/api/user/liked_chatbots/remove'
        : '/api/user/liked_chatbots/add'
      await axios.post(url, { chatbot_id: id })

      fetchItems()
    } catch (err) {
      context.handleError(err)
    }
  }

  const handleRecommendChatbot = async (id) => {
    try {
      const url = items.find((item) => item.id === id)?.isRecommended
        ? '/api/user/recommended_chatbots/remove'
        : '/api/user/recommended_chatbots/add'
      await axios.post(url, { chatbot_id: id })

      fetchItems()
    } catch (err) {
      context.handleError(err)
    }
  }

  useEffect(() => {
    fetchItems()
  }, [fetchItems, selectedCategory])

  if (!items.length)
    return (
      <div className='text-brand-violet-light flex h-64 flex-col items-center justify-center gap-[12px] text-[15px] leading-[1.5em]'>
        {selectedCategory !== 'eleo-chat-library-all-categories' ? (
          <T
            keyName='eleo-no-results-category'
            defaultValue='No search results for {filter} in {category}'
            params={{
              filter: debounceFilter,
              category: chatbotCategories.find((cat) => cat.value === selectedCategory)?.label,
            }}
          />
        ) : (
          <T
            keyName='eleo-no-results'
            defaultValue='No results for {filter}'
            params={{ filter: debounceFilter }}
          />
        )}
        <Microbutton
          variant='transparent-light'
          text={<T keyName='eleo-clear-search'>Clear search</T>}
          icon={<Close fontSize='inherit' />}
          onClick={() => setFilter('')}
        />
      </div>
    )

  return (
    <div className='flex  flex-col  bg-[#f5f5fa] p-[14px]  lg:px-[50px] lg:pb-[30px] lg:pt-[25px]'>
      <div className='flex flex-col gap-[10px] lg:gap-[16px]'>
        <ChatbotsCarouselHeader
          icon={<Search sx={{ height: '18px', width: '18px' }} />}
          isSearchActive={isSearchActive}
          searchHintsUI={<SearchHintsUI searchKey={debounceFilter} category={selectedCategory} />}
          clearSearch={clearSearch}
          disableDivider
        />
        <div>
          {isLoading && !items.length ? (
            <SectionSkeletonLoader yPosition='10' disableHeader disablePadding />
          ) : (
            <div className='grid grid-cols-2 gap-[10px] sm:flex sm:flex-wrap sm:items-center lg:gap-[20px]'>
              {items?.length ? (
                <>
                  {items.map((item) => (
                    <LibraryItem
                      handleLikeChatbot={handleLikeChatbot}
                      handleRecommendChatbot={handleRecommendChatbot}
                      key={item.id}
                      item={item}
                    />
                  ))}
                </>
              ) : null}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default SearchResults
