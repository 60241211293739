import { useTranslate } from '@tolgee/react'
import axios from 'axios'
import { ViewContext } from 'components/lib'
import { useCallback, useContext, useEffect, useState } from 'react'
import { ChatbotsCarousel } from '../ChatbotsCarousel'
import { useSearchParams } from 'react-router-dom'
import { AccessTimeFilled, Search } from '@mui/icons-material'
import SectionSkeletonLoader from './SectionSkeletonLoader'

export const RecentlyAdded = ({ filter, signal, refetchData, searchHintsUI, clearSearch }) => {
  const context = useContext(ViewContext)
  const [items, setItems] = useState([])
  const { t } = useTranslate()
  const params = useSearchParams()[0]
  const [isLoading, setIsLoading] = useState(true)
  const [numberPages, setNumberPages] = useState(1)
  const [totalChatbots, setTotalChatbots] = useState(0)

  const fetchItems = useCallback(async () => {
    setIsLoading(true)
    try {
      const category = params.get('category')
      const res = await axios.get(
        `/api/chat-bot/library?page=${numberPages}&pageSize=10&filter=${filter}${category ? `&category=${category}` : ''}`
      )
      if (numberPages === 1) setItems(res.data?.chatBots)
      else setItems((prev) => [...prev, ...res.data?.chatBots])
      setTotalChatbots(res.data?.total)
    } catch (err) {
      context.handleError(err)
    } finally {
      setIsLoading(false)
    }
  }, [filter, params, numberPages])

  useEffect(() => {
    fetchItems()
  }, [fetchItems, signal])

  if (isLoading && !items.length) return <SectionSkeletonLoader />

  const title = t('recently-added-chatbots-title', 'RECENTLY ADDED CHATBOTS')

  return (
    <div className='flex flex-col gap-10 bg-[#f5f5fa]'>
      <ChatbotsCarousel
        items={items}
        total={totalChatbots}
        title={title}
        icon={<AccessTimeFilled sx={{ height: 18, width: 18 }} />}
        refetchData={refetchData}
        seeMoreUrl='/chatbot/library/new'
        setNumberPages={setNumberPages}
      />
    </div>
  )
}
