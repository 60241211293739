/***
 *
 *   API Key Editor
 *   Create or edit a new/existing API key
 *
 **********/

import React, { useState, useEffect, useCallback } from 'react'
import Axios from 'axios'
import {
  Animate,
  AccountNav,
  Card,
  Form,
  Message,
  Breadcrumbs,
  TextInput,
  Loader,
  useNavigate,
  useLocation,
  useAPI,
} from 'components/lib'
import { useTranslate } from '@tolgee/react'

export function APIKeyEditor(props) {
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslate()

  // get the scopes
  const scopes = useAPI('/api/key/scopes')

  // state
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(false)
  const [newAPIKey, setNewAPIKey] = useState(false)

  const fetch = useCallback(async () => {
    setLoading(true)
    const res = await Axios.get(`/api/key/${location.search?.substring(4)}`)
    res.data.data.length ? setData(res.data.data[0]) : navigate('/404')
    setLoading(false)
  }, [location.search, navigate])

  useEffect(() => {
    // editing existing key?
    const id = location.search
    if (id) fetch(id)
  }, [fetch, location.search])

  if (scopes.loading) return <Loader />

  return (
    <Animate>
      <div className='px-[20px] py-[10px]'>
        <Breadcrumbs
          items={[
            { name: t('eleo-api-key-list', 'API Keys'), url: '/account/apikeys' },
            {
              name: t('eleo-api-key-editor-title', '{action} API Key', {
                action: data ? 'Edit' : 'Create',
              }),
              url: '/account/apikeys/create',
            },
          ]}
        />
      </div>
      {newAPIKey ? (
        <Message
          title={t('eleo-api-key-your-new-api-key', 'Your New API key')}
          type='warning'
          buttonText={t('eleo-api-key-back-button', 'Back to API Keys')}
          buttonLink='/account/apikeys'
          text={t(
            'eleo-api-key-store-safely',
            'Your API key is below. Please store this somewhere safe.'
          )}
        >
          <TextInput value={newAPIKey} showCopyToClipBoardIcon />
        </Message>
      ) : (
        <Card
          title={t('eleo-api-key-editor-title', '{action} API Key', {
            action: data ? 'Edit' : 'Create',
          })}
          loading={loading}
        >
          <Form
            inputs={{
              name: {
                label: t('eleo-api-key-name-label', 'Name'),
                type: 'text',
                required: true,
                value: data.name,
                errorMessage: t(
                  'eleo-api-key-name-error',
                  'Please provide a descriptive name for your key.'
                ),
              },
              scope: {
                type: 'checkbox',
                label: t('eleo-api-key-scope-label', 'Scope'),
                required: true,
                min: 1,
                default: data.scope,
                options: scopes?.data,
                errorMessage: t('eleo-api-key-scope-error', 'Please select at least one scope'),
              },
            }}
            url={data ? `/api/key/${data.id}` : '/api/key'}
            method={data ? 'PATCH' : 'POST'}
            buttonText={t('eleo-api-key-submit-button', '{action} API Key', {
              action: data ? 'Save Changes' : 'Create New',
            })}
            callback={(res) => {
              !data && setNewAPIKey(res?.data?.data?.full_key)
            }}
          />
        </Card>
      )}
    </Animate>
  )
}
