import { Fragment, useState } from 'react'
import { CheckIcon, MinusIcon } from '@heroicons/react/20/solid'
import { usePlans } from 'components/lib'
import { RadioGroup } from '@headlessui/react'
import { useSearchParams } from 'react-router-dom'
import { T } from '@tolgee/react'
import { periods } from 'helpers'

const sections = [
  {
    name: <T keyName='eleo-more-feature-main-header'>Features</T>,
    features: [
      {
        title: '40+ Languages',
        name: 'Operate in Your Preferred Language - Eleo is optimized for 8 popular languages, plus you have the capability to ask questions and receive answers in over 40 additional languages.',
        tiers: { Free: true, Basic: true, Standard: true, Pro: true, Team: true },
        toggle_title_id: 'eleo-more-feature-title-1',
        toggle_name_id: 'eleo-more-feature-name-1',
      },
      {
        title: 'Always Accessible',
        name: 'Use Eleo on any computer, tablet, or phone.',
        tiers: { Free: true, Basic: true, Standard: true, Pro: true, Team: true },
        toggle_title_id: 'eleo-more-feature-title-2',
        toggle_name_id: 'eleo-more-feature-name-2',
      },
      {
        title: 'Extensions',
        name: 'Keep Eleo at your fingertips with extensions for Chrome, Firefox, and Brave.',
        tiers: { Free: true, Basic: true, Standard: true, Pro: true, Team: true },
        toggle_title_id: 'eleo-more-feature-title-3',
        toggle_name_id: 'eleo-more-feature-name-3',
      },
      {
        title: 'Word Limit',
        name: 'Your monthly word limit for questions and answers.',
        tiers: {
          Free: '200 000',
          Basic: '500 000',
          Standard: '2 000 000',
          Pro: '6 000 000',
          Team: '6 000 000',
        },
        toggle_title_id: 'eleo-more-feature-title-4',
        toggle_name_id: 'eleo-more-feature-name-4',
      },
      {
        title: 'Image Limit',
        name: 'Your monthly limit for generated images.',
        tiers: { Free: '50', Basic: '200', Standard: '300', Pro: '1 000', Team: '1 000' },
        toggle_title_id: 'eleo-more-feature-title-5',
        toggle_name_id: 'eleo-more-feature-name-5',
      },
      {
        title: 'Additional Packages.',
        name: 'Access additional word and image packages.',
        tiers: { Basic: true, Standard: true, Pro: true, Team: true },
        toggle_title_id: 'eleo-more-feature-title-6',
        toggle_name_id: 'eleo-more-feature-name-6',
      },
    ],
  },
  {
    name: <T keyName='eleo-more-feature-main-header-chat'>Chat</T>,
    features: [
      {
        title: 'Multiple Languages',
        name: 'Chat with Eleo in any language.',
        tiers: { Free: true, Basic: true, Standard: true, Pro: true, Team: true },
        toggle_title_id: 'eleo-feature-chat-title-1',
        toggle_name_id: 'eleo-feature-chat-name-1',
      },
      {
        title: 'Specific Answers',
        name: 'Receive specific answers in the form of information, advice, translations, ideas, or news.',
        tiers: { Free: true, Basic: true, Standard: true, Pro: true, Team: true },
        toggle_title_id: 'eleo-feature-chat-title-2',
        toggle_name_id: 'eleo-feature-chat-name-2',
      },
      {
        title: 'Share',
        name: 'Quickly re-ask questions, copy, or forward the answers you receive.',
        tiers: { Free: true, Basic: true, Standard: true, Pro: true, Team: true },
        toggle_title_id: 'eleo-feature-chat-title-3',
        toggle_name_id: 'eleo-feature-chat-name-3',
      },
      {
        title: 'Contextual Chat',
        name: 'Engage in inspiring conversations with Eleo, thanks to its memory of previous answers.',
        tiers: { Free: true, Basic: true, Standard: true, Pro: true, Team: true },
        toggle_title_id: 'eleo-feature-chat-title-4',
        toggle_name_id: 'eleo-feature-chat-name-4',
      },
      {
        title: 'Chat History',
        name: 'Access your chat history anytime.',
        tiers: { Basic: true, Standard: true, Pro: true, Team: true },
        toggle_title_id: 'eleo-feature-chat-title-5',
        toggle_name_id: 'eleo-feature-chat-name-5',
      },
      {
        title: 'Chat Continuation',
        name: 'Pick up and continue previous chats at any time.',
        tiers: { Basic: true, Standard: true, Pro: true, Team: true },
        toggle_title_id: 'eleo-feature-chat-title-6',
        toggle_name_id: 'eleo-feature-chat-name-6',
      },
      {
        title: 'Latest News',
        name: 'Eleo provides you with the latest information and news.',
        tiers: { Pro: true, Team: true },
        toggle_title_id: 'eleo-feature-chat-title-7',
        toggle_name_id: 'eleo-feature-chat-name-7',
      },
    ],
  },
  {
    name: <T keyName='eleo-more-feature-main-header-writer'>Writer</T>,
    features: [
      {
        title: 'Text Generation',
        name: 'Generate new articles, documents, posts, descriptions, stories, and emails in seconds.',
        tiers: { Free: true, Basic: true, Standard: true, Pro: true, Team: true },
        toggle_title_id: 'eleo-feature-writer-title-1',
        toggle_name_id: 'eleo-feature-writer-name-1',
      },
      {
        title: 'Text Editing',
        name: 'Quickly edit and review your own text.',
        tiers: { Free: true, Basic: true, Standard: true, Pro: true, Team: true },
        toggle_title_id: 'eleo-feature-writer-title-2',
        toggle_name_id: 'eleo-feature-writer-name-2',
      },
      {
        title: 'Text Options',
        name: "Choose the text's format, tone, and author according to your needs.",
        tiers: { Free: true, Basic: true, Standard: true, Pro: true, Team: true },
        toggle_title_id: 'eleo-feature-writer-title-3',
        toggle_name_id: 'eleo-feature-writer-name-3',
      },
      {
        title: 'Additional Elements',
        name: 'Specify additional elements you want to include in the document.',
        tiers: { Free: true, Basic: true, Standard: true, Pro: true, Team: true },
        toggle_title_id: 'eleo-feature-writer-title-4',
        toggle_name_id: 'eleo-feature-writer-name-4',
      },
      {
        title: 'Text Segment Editing',
        name: 'Quickly highlight and modify the section of the document you wish to change.',
        tiers: { Free: true, Basic: true, Standard: true, Pro: true, Team: true },
        toggle_title_id: 'eleo-feature-writer-title-5',
        toggle_name_id: 'eleo-feature-writer-name-5',
      },
      {
        title: 'Fast Text Regeneration',
        name: 'With a single click, regenerate the text.',
        tiers: { Free: true, Basic: true, Standard: true, Pro: true, Team: true },
        toggle_title_id: 'eleo-feature-writer-title-6',
        toggle_name_id: 'eleo-feature-writer-name-6',
      },
      {
        title: 'Easy Copying',
        name: 'Quickly copy and share your new text.',
        tiers: { Free: true, Basic: true, Standard: true, Pro: true, Team: true },
        toggle_title_id: 'eleo-feature-writer-title-7',
        toggle_name_id: 'eleo-feature-writer-name-7',
      },
      {
        title: 'Writing Styles',
        name: 'Craft documents like a pro by selecting the appropriate writing style.',
        tiers: { Basic: true, Standard: true, Pro: true, Team: true },
        toggle_title_id: 'eleo-feature-writer-title-8',
        toggle_name_id: 'eleo-feature-writer-name-8',
      },
      {
        title: 'Templates',
        name: 'Work even faster using your saved queries.',
        tiers: { Standard: true, Pro: true, Team: true },
        toggle_title_id: 'eleo-feature-writer-title-10',
        toggle_name_id: 'eleo-feature-writer-name-10',
      },
      // {
      //   title: 'Working Flow',
      //   name: 'Craft professional documents with the help of outlines and templates (working flow).',
      //   tiers: { Pro: true, Team: true },
      //   toggle_title_id: "eleo-feature-writer-title-11",
      //   toggle_name_id: "eleo-feature-writer-name-11"
      // },
    ],
  },
  {
    name: <T keyName='eleo-more-feature-main-header-advisor'>Advisor</T>,
    features: [
      {
        title: 'Interdisciplinary Knowledge',
        name: 'Gain instant access to information across various fields, including science, sales, marketing, law, business, and medicine.',
        tiers: { Free: true, Basic: true, Standard: true, Pro: true, Team: true },
        toggle_title_id: 'eleo-feature-advisor-title-1',
        toggle_name_id: 'eleo-feature-advisor-name-1',
      },
      {
        title: 'Response Length',
        name: "Maximum length of the answer you'll receive.",
        tiers: { Free: '500', Basic: '2 000', Standard: '3 000', Pro: '3 000', Team: '3 000' },
        toggle_title_id: 'eleo-feature-advisor-title-2',
        toggle_name_id: 'eleo-feature-advisor-name-2',
      },
      {
        title: 'Templates',
        name: 'Work even more efficiently using your saved queries.',
        tiers: { Standard: true, Pro: true, Team: true },
        toggle_title_id: 'eleo-feature-advisor-title-3',
        toggle_name_id: 'eleo-feature-advisor-name-3',
      },
    ],
  },
  {
    name: <T keyName='eleo-more-feature-main-header-translator'>Translator</T>,
    features: [
      {
        title: 'Multilingual Capability',
        name: 'Translate any text into over 40 languages from around the world.',
        tiers: { Free: true, Basic: true, Standard: true, Pro: true, Team: true },
        toggle_title_id: 'eleo-feature-translator-title-1',
        toggle_name_id: 'eleo-feature-translator-name-1',
      },
      {
        title: 'Native Speaker',
        name: 'Quickly adjust and refine translations with the built-in Native Speaker feature.',
        tiers: { Free: true, Basic: true, Standard: true, Pro: true, Team: true },
        toggle_title_id: 'eleo-feature-translator-title-2',
        toggle_name_id: 'eleo-feature-translator-name-2',
      },
      {
        title: 'Easy Copying',
        name: 'Quickly copy and share your translations.',
        tiers: { Free: true, Basic: true, Standard: true, Pro: true, Team: true },
        toggle_title_id: 'eleo-feature-translator-title-3',
        toggle_name_id: 'eleo-feature-translator-name-3',
      },
      {
        title: 'Reading and Speaking',
        name: 'Eleo will read your queries and answers aloud for you.',
        tiers: { Standard: true, Pro: true, Team: true },
        toggle_title_id: 'eleo-feature-translator-title-4',
        toggle_name_id: 'eleo-feature-translator-name-4',
      },
    ],
  },
  {
    name: <T keyName='eleo-more-feature-main-header-great-ideas'>Great Ideas</T>,
    features: [
      {
        title: 'Inspiring Ideas',
        name: 'Discover new ideas on any topic within seconds, including ideas for names, descriptions, plans, titles, promotions, products, and services.',
        tiers: { Free: true, Basic: true, Standard: true, Pro: true, Team: true },
        toggle_title_id: 'eleo-feature-great-ideas-title-1',
        toggle_name_id: 'eleo-feature-great-ideas-name-1',
      },
      {
        title: 'Various Tones',
        name: "Utilize various tones of Eleo's responses.",
        tiers: { Free: true, Basic: true, Standard: true, Pro: true, Team: true },
        toggle_title_id: 'eleo-feature-great-ideas-title-2',
        toggle_name_id: 'eleo-feature-great-ideas-name-2',
      },
      {
        title: 'Creativity Control',
        name: "Control Eleo's creativity to receive ideas ranging from straightforward to highly imaginative.",
        tiers: { Free: true, Basic: true, Standard: true, Pro: true, Team: true },
        toggle_title_id: 'eleo-feature-great-ideas-title-3',
        toggle_name_id: 'eleo-feature-great-ideas-name-3',
      },
    ],
  },
  {
    name: <T keyName='eleo-more-feature-main-header-images'>Images</T>,
    features: [
      {
        title: 'Incredible Images',
        name: 'With a single click, create new images of any type in any style.',
        tiers: { Free: true, Basic: true, Standard: true, Pro: true, Team: true },
        toggle_title_id: 'eleo-feature-images-title-1',
        toggle_name_id: 'eleo-feature-images-name-1',
      },
      {
        title: 'Image Modification',
        name: 'Enhance your image by creating its modifications.',
        tiers: { Basic: true, Standard: true, Pro: true, Team: true },
        toggle_title_id: 'eleo-feature-images-title-2',
        toggle_name_id: 'eleo-feature-images-name-2',
      },
      {
        title: 'Custom Image Editing',
        name: 'Upload your own image and modify it in any way you desire.',
        tiers: { Basic: true, Standard: true, Pro: true, Team: true },
        toggle_title_id: 'eleo-feature-images-title-3',
        toggle_name_id: 'eleo-feature-images-name-3',
      },
      {
        title: 'Image Enhancement',
        name: 'Quickly enhance image quality, add or remove colors.',
        tiers: { Basic: true, Standard: true, Pro: true, Team: true },
        toggle_title_id: 'eleo-feature-images-title-4',
        toggle_name_id: 'eleo-feature-images-name-4',
      },
      {
        title: 'Image Customization',
        name: 'Freely customize your image, including its style, format, background, and details.',
        tiers: { Basic: true, Standard: true, Pro: true, Team: true },
        toggle_title_id: 'eleo-feature-images-title-5',
        toggle_name_id: 'eleo-feature-images-name-5',
      },
    ],
  },
  {
    name: <T keyName='eleo-more-feature-main-header-story'>Your Story</T>,
    features: [
      {
        title: 'Personalize your experience',
        name: "Personalize your experience with 'Your Story.' Input your personal, company, or product details to receive customized solutions and insights, turning Eleo into a tool that thinks and responds like you do.",
        tiers: { Free: true, Basic: true, Standard: true, Pro: true, Team: true },
        toggle_title_id: 'eleo-feature-story-title-1',
        toggle_name_id: 'eleo-feature-story-name-1',
      },
      {
        title: 'Easy to use',
        name: 'Crafting Your Story is simple - just a few sentences or a quick text copy from your website, brochure, or pitch.',
        tiers: { Free: true, Basic: true, Standard: true, Pro: true, Team: true },
        toggle_title_id: 'eleo-feature-story-title-2',
        toggle_name_id: 'eleo-feature-story-name-2',
      },
      {
        title: 'Fine-tuning',
        name: "'Your Story' enables you to fine-tune Eleo's responses after one click, maximizing the potential of AI to suit your needs.",
        tiers: { Free: true, Basic: true, Standard: true, Pro: true, Team: true },
        toggle_title_id: 'eleo-feature-story-title-3',
        toggle_name_id: 'eleo-feature-story-name-3',
      },
      {
        title: 'Your Stories',
        name: 'Store various versions of Your Story, selecting the most appropriate one for different contexts.',
        tiers: { Free: true, Basic: true, Standard: true, Pro: true, Team: true },
        toggle_title_id: 'eleo-feature-story-title-4',
        toggle_name_id: 'eleo-feature-story-name-4',
      },
      {
        title: 'Use with chatbots',
        name: 'Deploy your personalized Chatbot in moments with Your Story, creating an automated assistant that understands and reflects your background to everyone from clients to colleagues and friends.',
        tiers: { Free: true, Basic: true, Standard: true, Pro: true, Team: true },
        toggle_title_id: 'eleo-feature-story-title-5',
        toggle_name_id: 'eleo-feature-story-name-5',
      },
    ],
  },
  {
    name: <T keyName='eleo-more-feature-main-header-chatbot'>Chatbot</T>,
    features: [
      {
        title: 'Quick setup',
        name: 'Get your Chatbot up and running in moments, perfect for any occasion.',
        tiers: { Free: true, Basic: true, Standard: true, Pro: true, Team: true },
        toggle_title_id: 'eleo-feature-chatbot-title-1',
        toggle_name_id: 'eleo-feature-chatbot-name-1',
      },
      {
        title: 'Multiple Chatbots',
        name: 'Run multiple Chatbots to fit your personal and business needs.',
        tiers: { Free: true, Basic: true, Standard: true, Pro: true, Team: true },
        toggle_title_id: 'eleo-feature-chatbot-title-2',
        toggle_name_id: 'eleo-feature-chatbot-name-2',
      },
      {
        title: '50 languages',
        name: 'Chatbot ready to assist your customers around the clock, in 50 different languages.',
        tiers: { Free: true, Basic: true, Standard: true, Pro: true, Team: true },
        toggle_title_id: 'eleo-feature-chatbot-title-3',
        toggle_name_id: 'eleo-feature-chatbot-name-3',
      },
      {
        title: 'Your Stories',
        name: 'Store various versions of Your Story, selecting the most appropriate one for different contexts.',
        tiers: { Free: true, Basic: true, Standard: true, Pro: true, Team: true },
        toggle_title_id: 'eleo-feature-chatbot-title-4',
        toggle_name_id: 'eleo-feature-chatbot-name-4',
      },
      {
        title: 'Personalized',
        name: 'Personalize the tone, introduction, chat icon, and avatar to match your brand’s voice.',
        tiers: { Free: true, Basic: true, Standard: true, Pro: true, Team: true },
        toggle_title_id: 'eleo-feature-chatbot-title-5',
        toggle_name_id: 'eleo-feature-chatbot-name-5',
      },
      {
        title: 'Easy installation',
        name: 'Place it on your website (just copy and paste a short code), or share its link via emails and social media to widen your customer engagement (your Chatbot can live on the Eleo site, with easily shared links)',
        tiers: { Free: true, Basic: true, Standard: true, Pro: true, Team: true },
        toggle_title_id: 'eleo-feature-chatbot-title-6',
        toggle_name_id: 'eleo-feature-chatbot-name-6',
      },
      {
        title: 'Always up-to-date',
        name: "Your Chatbot stays up-to-date with your latest information, drawing from 'Your Story' - you can easily edit and customize it anytime.",
        tiers: { Free: true, Basic: true, Standard: true, Pro: true, Team: true },
        toggle_title_id: 'eleo-feature-chatbot-title-7',
        toggle_name_id: 'eleo-feature-chatbot-name-7',
      },
    ],
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export function Plans() {
  const { data, loading } = usePlans()
  const [searchParams] = useSearchParams()
  const defaultFrequency = searchParams.get('period') || 'month'
  const [frequency, setFrequency] = useState(
    periods.find((item) => item.value === defaultFrequency)
  )

  const tiers = data?.raw?.plans
  if (loading || !tiers) return

  return (
    <div className='bg-white'>
      <div className='mx-auto max-w-7xl px-6 lg:px-8'>
        <div className='relative mx-auto flex justify-center pt-8 text-center'>
          <p className='text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl'>
            <T keyName='eleo-features-and-benefits'>Features and Benefits</T>
          </p>
          <RadioGroup
            value={frequency}
            onChange={setFrequency}
            className='absolute right-0 grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200'
          >
            <RadioGroup.Label className='sr-only'>Payment frequency</RadioGroup.Label>
            {periods.map((option) => (
              <RadioGroup.Option
                key={option.value}
                value={option}
                className={({ checked }) =>
                  classNames(
                    checked ? 'bg-brand-violet text-white' : 'text-brand-text-secondary',
                    'cursor-pointer rounded-full px-2.5 py-1'
                  )
                }
              >
                <span>
                  <T keyName={`eleo-payment-frequency-${option.value}`}>{option.label}</T>
                </span>
              </RadioGroup.Option>
            ))}
          </RadioGroup>
        </div>

        {/* xs to lg */}
        <div className='mx-auto mt-12 max-w-md space-y-8 sm:mt-16 lg:hidden'>
          {tiers.map((tier) => (
            <section
              key={tier.id}
              className={classNames(
                tier.mostPopular ? 'rounded-xl bg-gray-400/5 ring-1 ring-inset ring-gray-200' : '',
                'p-8'
              )}
            >
              <h3 id={tier.id} className='text-sm font-semibold leading-6 text-gray-900'>
                <T keyName={`eleo-tier-${tier.id}`}>{tier.name}</T>
              </h3>
              <p className='mt-2 flex items-baseline gap-x-1 text-gray-900'>
                <span className='text-4xl font-bold'>{`${tier.currency.symbol}${
                  frequency.value === 'year'
                    ? Math.round((tier.pricing[frequency.value].price * 10) / 12) / 10
                    : tier.pricing[frequency.value].price
                }`}</span>
                <span className='text-sm font-semibold'>
                  <T keyName='eleo-tier-price-suffix'>{frequency.priceSuffix}</T>
                </span>
              </p>
              <a
                href={tier.href}
                aria-describedby={tier.id}
                className={classNames(
                  tier.mostPopular
                    ? 'bg-indigo-600 text-white hover:bg-indigo-500'
                    : 'text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300',
                  'mt-8 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                )}
              >
                {/* <T keyName='eleo-tier-buy-plan'>Buy plan</T> */}
                {tier.id === 'free' ? (
                  <T keyName={`eleo-tier-try-out`}>Try out</T>
                ) : (
                  <T keyName={`eleo-tier-buy-plan`}>Buy plan</T>
                )}
              </a>
              <ul className='mt-10 space-y-4 text-sm leading-6 text-gray-900'>
                {sections.map((section, i) => (
                  <li key={i}>
                    <ul className='space-y-4'>
                      {section.features.map((feature) =>
                        feature.tiers[tier.name] ? (
                          <li key={feature.name} className='flex gap-x-3'>
                            <CheckIcon
                              className='h-6 w-5 flex-none text-indigo-600'
                              aria-hidden='true'
                            />
                            <span>
                              <h5 className='text-brand-text-primary font-bold'>
                                <T keyName={feature.toggle_title_id}>{feature.title}</T>
                              </h5>
                              <span className='text-brand-text-secondary'>
                                <T keyName={feature.toggle_name_id}>{feature.name}</T>
                              </span>
                              {typeof feature.tiers[tier.name] === 'string' ? (
                                <span className='text-sm leading-6 text-gray-500'>
                                  ({feature.tiers[tier.name]})
                                </span>
                              ) : null}
                            </span>
                          </li>
                        ) : null
                      )}
                    </ul>
                  </li>
                ))}
              </ul>
            </section>
          ))}
        </div>

        {/* lg+ */}
        <div className='isolate mt-12 hidden lg:block'>
          <div className='relative -mx-8'>
            {tiers.some((tier) => tier.mostPopular) ? (
              <div className='absolute inset-x-4 inset-y-0 -z-10 flex'>
                <div
                  className='flex w-1/5 px-4'
                  aria-hidden='true'
                  style={{
                    marginLeft: `${(tiers.findIndex((tier) => tier.mostPopular) + 1) * 20}%`,
                  }}
                >
                  <div className='w-full rounded-t-xl border-x border-t border-gray-900/10 bg-gray-400/5' />
                </div>
              </div>
            ) : null}
            <table className='w-full table-fixed border-separate border-spacing-x-8 text-left'>
              <caption className='sr-only'>Pricing plan comparison</caption>
              <colgroup>
                <col className='w-1/5' />
                <col className='w-1/5' />
                <col className='w-1/5' />
                <col className='w-1/5' />
                <col className='w-1/5' />
              </colgroup>
              <thead>
                <tr>
                  <td />
                  {tiers.map((tier) => (
                    <th key={tier.id} scope='col' className='px-6 pt-6 xl:px-8 xl:pt-8'>
                      <div className='text-sm font-semibold leading-7 text-gray-900'>
                        <T keyName={`eleo-tier-${tier.id}`}>{tier.name}</T>
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope='row'>
                    <span className='sr-only'>Price</span>
                  </th>
                  {tiers.map((tier) => (
                    <td key={tier.id} className='px-6 pt-2 xl:px-8'>
                      <div className='flex items-baseline gap-x-1 text-gray-900'>
                        <span className='text-4xl font-bold'>{`${tier.currency.symbol}${
                          frequency.value === 'year'
                            ? Math.round((tier.pricing[frequency.value].price * 10) / 12) / 10
                            : tier.pricing[frequency.value].price
                        }`}</span>
                        <span className='text-sm font-semibold leading-6'>
                          <T keyName='eleo-tier-price-suffix'>{frequency.priceSuffix}</T>
                        </span>
                      </div>
                      <a
                        href={tier.href}
                        className={classNames(
                          tier.mostPopular
                            ? 'bg-indigo-600 text-white hover:bg-indigo-500'
                            : 'text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300',
                          'mt-8 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                        )}
                      >
                        {tier.id === 'free' ? (
                          <T keyName={`eleo-tier-try-out`}>Try out</T>
                        ) : (
                          <T keyName={`eleo-tier-buy-plan`}>Buy plan</T>
                        )}
                      </a>
                    </td>
                  ))}
                </tr>
                {sections.map((section, sectionIdx) => (
                  <Fragment key={section.name}>
                    <tr>
                      <th
                        scope='colgroup'
                        colSpan={5}
                        className={classNames(
                          sectionIdx === 0 ? 'pt-8' : 'pt-16',
                          'text-m pb-4 font-semibold leading-6 text-gray-900'
                        )}
                      >
                        {section.name}
                        <div className='absolute inset-x-8 mt-4 h-px bg-gray-900/10' />
                      </th>
                    </tr>
                    {section.features.map((feature) => (
                      <tr key={feature.name}>
                        <th
                          scope='row'
                          className='text-brand-text-secondary py-4 text-sm font-normal leading-6'
                        >
                          <h5 className='text-brand-text-primary font-bold'>
                            <T keyName={feature.toggle_title_id}>{feature.title}</T>
                          </h5>
                          <span className='text-brand-text-secondary'>
                            <T keyName={feature.toggle_name_id}>{feature.name}</T>
                          </span>
                          <div className='absolute inset-x-8 mt-4 h-px bg-gray-900/5' />
                        </th>
                        {tiers.map((tier) => (
                          <td key={tier.id} className='px-6 py-4 xl:px-8'>
                            {typeof feature.tiers[tier.name] === 'string' ? (
                              <div className='text-center text-sm leading-6 text-gray-500'>
                                {feature.tiers[tier.name]}
                              </div>
                            ) : (
                              <>
                                {feature.tiers[tier.name] === true ? (
                                  <CheckIcon
                                    className='mx-auto h-5 w-5 text-indigo-600'
                                    aria-hidden='true'
                                  />
                                ) : (
                                  <MinusIcon
                                    className='mx-auto h-5 w-5 text-gray-400'
                                    aria-hidden='true'
                                  />
                                )}

                                <span className='sr-only'>
                                  {feature.tiers[tier.name] === true ? 'Included' : 'Not included'}{' '}
                                  in {tier.name}
                                </span>
                              </>
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
