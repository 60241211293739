import { ChatBubble } from '@mui/icons-material'
import { useTranslate } from '@tolgee/react'
import axios from 'axios'
import { ViewContext } from 'components/lib'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { ChatbotsCarousel } from '../ChatbotsCarousel'
import SectionSkeletonLoader from './SectionSkeletonLoader'
import Divider from '../Divider'

export const RecentlyUsed = ({ filter, signal, refetchData, clearSearch, searchHintsUI }) => {
  const context = useContext(ViewContext)
  const [items, setItems] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const { t } = useTranslate()
  const params = useSearchParams()[0]
  const [numberPages, setNumberPages] = useState(1)
  const [totalChatbots, setTotalChatbots] = useState(0)

  const fetchItems = useCallback(async () => {
    try {
      setIsLoading(true)
      const category = params.get('category')
      const res = await axios.get(
        `/api/chat-bot/library/recent?page=${numberPages}&pageSize=10&filter=${filter}${category ? `&category=${category}` : ''}`
      )
      if (numberPages === 1) setItems(res.data?.chatBots)
      else setItems((prev) => [...prev, ...res.data?.chatBots])
      setTotalChatbots(res.data?.total)
    } catch (err) {
      context.handleError(err)
    } finally {
      setIsLoading(false)
    }
  }, [filter, params, numberPages])

  useEffect(() => {
    fetchItems()
  }, [fetchItems, signal])

  if (isLoading && !items.length) {
    return <SectionSkeletonLoader />
  }

  if (!items.length) return

  const title = t('recently-used-chatbots-title', 'KEEP TALKING TO')

  return (
    <div className='bg-[#f5f5fa]'>
      <ChatbotsCarousel
        items={items}
        title={title}
        total={totalChatbots}
        icon={<ChatBubble sx={{ height: 18, width: 18 }} />}
        refetchData={refetchData}
        seeMoreUrl='/chatbot/library/recent'
        setNumberPages={setNumberPages}
      />
      <Divider />
    </div>
  )
}

export default RecentlyUsed
