/***
 *
 *   CHAT
 *   Tool for chatting with AI
 *
 **********/

import axios from 'axios'
import { HelpTooltips } from 'components/help/helpTooltips'
import { useWindowDimensions } from 'components/hooks/window'
import {
  Animate,
  Loader,
  useDetectLandscapeMode,
  ViewContext,
  WebCamComponent,
} from 'components/lib'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useSearchParams } from 'react-router-dom'
import { chatInputHistory, setChatInputHistory } from 'store/toolInputHistoriesHolder/chat'
import ChatPanel from 'toolComponents/chat/ChatPanel'
import ChatSelector from 'toolComponents/chat/ChatSelector'
import { ResizableContainer } from 'ui'
import { v4 as uuidv4 } from 'uuid'
import { T, useTranslate } from '@tolgee/react'
import Tour from 'components/tour'
import { useDispatch } from 'react-redux'
import { useDocumentContext } from 'components/hooks/context'
import imageCompression from 'browser-image-compression'
import { errorToast } from 'helpers'

// Tour steps
const steps = [
  {
    target: '#model-selector',
    content: <T keyName='eleo-tour-chat-2'></T>,
  },
  {
    target: '#chat-input',
    content: <T keyName='eleo-tour-chat-1'></T>,
  },
  {
    target: '#conversation-context',
    content: <T keyName='eleo-tour-chat-3'></T>,
  },
  {
    target: '#audio-switch',
    content: <T keyName='eleo-tour-chat-4'></T>,
  },
  {
    target: '#speech-to-text',
    content: <T keyName='eleo-tour-chat-5'></T>,
  },
  {
    target: '#message-context',
    content: <T keyName='eleo-tour-chat-6'></T>,
  },
  {
    target: '#chat-history',
    content: <T keyName='eleo-tour-chat-7'></T>,
  },
]

export function Chat() {
  const { t } = useTranslate()
  const context = useContext(ViewContext)
  const { width } = useWindowDimensions()
  const location = useLocation()
  const [params, setParams] = useSearchParams()
  const chatInputHistoryValues = useSelector(chatInputHistory)
  const dispatchAction = useDispatch()
  const documentContext = useDocumentContext('chat', location.state?.contextKey)
  const messageContext = useDocumentContext('chat-message')

  const [showWebCam, setShowWebCam] = useState(false)

  const { isLandscape } = useDetectLandscapeMode()

  let importedHistory = location.state ? location.state.history : undefined

  const [chatId, setChatId] = useState(importedHistory ? null : chatInputHistoryValues.chatId)
  const [chatLog, setChatLog] = useState(importedHistory ?? chatInputHistoryValues.chatLog)
  const [chatHistory, setChatHistory] = useState([])
  const [showHistory, setShowHistory] = useState(chatInputHistoryValues.showHistory)
  // Chat history for the purpose of context
  const [activeHistory, setActiveHistory] = useState(
    importedHistory ?? chatInputHistoryValues.activeHistory
  )

  useEffect(() => {
    dispatchAction(
      setChatInputHistory({
        chatLog,
        activeHistory,
        chatId,
        showHistory,
      })
    )
  }, [chatLog, activeHistory, chatId, showHistory, dispatchAction])

  const layoutMedium = width >= 768

  const getChatHistory = useCallback(async (pages) => {
    try {
      const res = await axios.get(`/api/chats?pages=${pages}`)
      setChatHistory(res.data)
    } catch (err) {
      console.log(err)
    }
  }, [])

  // Init chat history
  useEffect(() => {
    getChatHistory(1)
  }, [getChatHistory])

  const loadChat = useCallback(
    async (id) => {
      if (id === chatId) return
      try {
        const res = await axios.get(`/api/chat/${id}`)
        const history = res.data.history.map((item) => ({
          content: item.content,
          role: item.role,
          attachedFileInfo: item.attachedFileInfo,
          image: item.image,
        }))
        setActiveHistory(history)
        setChatLog(history)
        setChatId(res.data.chatId)

        // Handle document context
        documentContext.clearContext()
        if (res.data.stories?.length) {
          res.data.stories.forEach((item) => {
            const storyData = documentContext.stories.data.find((story) => story.id === item.id)
            documentContext.addDocument({
              label: storyData.label,
              type: 'story',
              content: storyData.content,
              id: storyData.id,
            })
          })
        }

        if (width < 1024) setShowHistory(false)
      } catch (err) {
        context.handleError(err)
      }
    },
    [chatId, context, width]
  )

  async function handleTakenPicUpload(file) {
    // Allow only 1 image at a time
    messageContext.removeDocumentsOfType('image')

    const id = uuidv4()
    messageContext.addDocument({
      id: id,
      label: file.name,
      content: null,
      type: 'image',
      invalid: true,
      metadata: { type: file.type },
    })

    const options = {
      maxSizeMB: 0.1,
      maxWidthOrHeight: 1024,
      useWebWorker: true,
    }

    try {
      const compressedFile = await imageCompression(file, options)

      const reader = new FileReader()
      reader.onload = () => {
        messageContext.setDocContext((prev) =>
          prev.map((item) =>
            item.id === id ? { ...item, content: reader.result, invalid: false } : item
          )
        )
      }
      reader.onerror = function () {
        errorToast(t('eleo-error-image-upload', 'Failed to upload your image'))
      }

      reader.readAsDataURL(compressedFile)
    } catch (error) {
      console.log(error)
    } finally {
      setShowWebCam(false)
    }
  }

  // Load chat id from query params
  useEffect(() => {
    const id = params.get('id')
    if (id) {
      loadChat(id)
      setParams()
    }

    const question = params.get('question')
    const response = params.get('response')
    if (question && response) {
      const history = [
        { content: question, role: 'user' },
        { content: response, role: 'assistant' },
      ]

      setActiveHistory(history)
      setChatLog(history)

      setParams()
    }
  }, [loadChat, params, setParams])

  const historyMaxWidth = Math.min(600, Math.floor(width * 0.4))

  const SelectorComponent = (
    <ChatSelector
      loadChat={loadChat}
      setChatLog={setChatLog}
      setChatId={setChatId}
      setActiveHistory={setActiveHistory}
      chatId={chatId}
      showHistory={showHistory}
      setShowHistory={setShowHistory}
      chatHistory={chatHistory}
      setChatHistory={setChatHistory}
    />
  )

  return (
    <Animate type='pop'>
      <div className='flex h-full w-full'>
        {layoutMedium ? (
          <ResizableContainer
            isOpen={showHistory}
            setIsOpen={setShowHistory}
            storageKey='chat'
            minWidth={250}
            initWidth={275}
            hideResizeHandler={isLandscape}
            maxWidth={historyMaxWidth}
          >
            {SelectorComponent}
          </ResizableContainer>
        ) : (
          showHistory && <div className='w-full overflow-x-clip'>{SelectorComponent}</div>
        )}

        {(layoutMedium || !showHistory) && (
          <ChatPanel
            chatLog={chatLog}
            setChatLog={setChatLog}
            chatId={chatId}
            setChatId={setChatId}
            showHistory={showHistory}
            setShowHistory={setShowHistory}
            activeHistory={activeHistory}
            setActiveHistory={setActiveHistory}
            getChatHistory={getChatHistory}
            documentContext={documentContext}
            setShowWebCam={setShowWebCam}
            messageContext={messageContext}
          />
        )}
        <HelpTooltips tool='chat' />
        <Tour steps={steps} name='chat' />
        {showWebCam && (
          <WebCamComponent
            handleTakenPicUpload={handleTakenPicUpload}
            setShowWebCam={setShowWebCam}
          />
        )}
      </div>
    </Animate>
  )
}
