import { T, useTranslate } from '@tolgee/react'
import axios from 'axios'
import classNames from 'classnames'
import { addContextToFormData } from 'components/document-context/helper'
import { useDocumentContext } from 'components/hooks/context'
import { useStream } from 'components/hooks/stream'
import { useChatBotVisibilityOptions } from 'components/hooks/useChatBotVisibilityOptions'
import { Loader, ViewContext } from 'components/lib'
import { errorToast, getLandingUrl, languages, transformDashToSpace } from 'helpers'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useSearchParams } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'
import { UserGroupsAPIService } from 'service/user-groups-api.service'
import { chatbotLibrary, setLibraryChatbotData } from 'store/toolInputHistoriesHolder/chatbot'
import AudioPlayer from 'toolComponents/generic/audio/AudioPlayer'
import { Button } from 'ui'
import { v4 as uuidv4 } from 'uuid'
import defaultAvatar from '../../images/new-logos/logo-oval.png'
import eleoLogo from '../../images/new-logos/new-logo.png'
import ChatBotHistory from './chatBotHistory'
import ChatBotUi from './chatBotUi'
import ChatNotActive from './chatNotActive'

export const ChatBotGeneralLayout = ({
  chatBotName,
  setChatIconSizes,
  setIsVisible,
  widget = false,
  visible = true,
  url = '/api/chat-bot/conversation',
  showUserHistory = false,
  showHeader = true,
  isFromLibrary = false,
  userAccountId,
}) => {
  const context = useContext(ViewContext)
  const params = useParams()

  const chatInputHistoryValues = useSelector(chatbotLibrary)

  const dispatchAction = useDispatch()
  const storeFormData = chatInputHistoryValues[params.botName]?.form
  const storeHistoryData = chatInputHistoryValues[params.botName]?.history
  const { state: completionState, abortController, fetch: fetchCompletion } = useStream(false) // TODO add abort logic
  const [chatBotSettings, setChatBotSettings] = useState('')
  const [settingsLoading, setSettingsLoading] = useState('')
  const [isUserAllowedToInteractWithBot, setIsUserAllowedToInteractWithBot] = useState(true)
  const [message, setMessage] = useState(storeFormData?.inputValue ?? '')
  const [userInfo, setUserInfo] = useState(JSON.parse(localStorage.getItem('user')))
  const [chatBotHistory, setChatBotHistory] = useState([])
  const [historyIsVisible, setHistoryIsVisible] = useState(storeHistoryData?.showHistory ?? false)
  const [historyIsLoading, setHistoryIsLoading] = useState(false)
  const [chatId, setChatId] = useState(storeHistoryData?.chatId ?? null)
  const [messages, setMessages] = useState(storeHistoryData?.chatLog ?? [])
  const [language, setLanguage] = useState(storeFormData?.language ?? 'auto')
  const [isSearchWeb, setSearchWeb] = useState(storeFormData?.isSearchWeb ?? false)

  const [searchParams, setSearchParams] = useSearchParams()
  const { t } = useTranslate()
  const [audioStream, setAudioStream] = useState()

  const [isAutoAudio, setIsAutoAudio] = useState(storeFormData?.isAutoAudio ?? false)
  const [isGenerating, setIsGenerating] = useState(false)
  const [ttsIndex, setTtsIndex] = useState()

  const { visibilityOptionsEnum } = useChatBotVisibilityOptions()

  const visibilityOptionCheck =
    Object.values(visibilityOptionsEnum).includes(chatBotSettings?.visibilityOption) ||
    chatBotSettings?.settings?.publishing?.forum
  const isIntendedForGroups =
    chatBotSettings?.visibilityOption === visibilityOptionsEnum.GROUPS ||
    (chatBotSettings?.settings?.publishing?.forum && chatBotSettings?.relatedGroups.length)

  //@user groups related
  const checkUserEligibilityToUseChatBot = async () => {
    try {
      const { data } = await UserGroupsAPIService.checkChatbotEligibility({
        chatBotId: chatBotSettings.id,
      })
      // /@ data -> returns a boolean value indicating whether the requester has access to the chatbot or not
      setIsUserAllowedToInteractWithBot(data)
    } catch (err) {
      //in case of error, or unauthenticated request prevent to show chatbot interface
      setIsUserAllowedToInteractWithBot(false)
    }
  }

  const documentContext = useDocumentContext(params.botName || chatBotName)

  useEffect(() => {
    if (!widget && chatBotSettings && visibilityOptionCheck) {
      dispatchAction(
        setLibraryChatbotData({
          chatbotId: params.botName,
          data: {
            history: {
              chatLog: messages,
              activeHistory: [],
              chatId: chatId,
              showHistory: historyIsVisible,
            },
            form: {
              inputValue: message,
              language: language,
              isAutoAudio: isAutoAudio,
              isSearchWeb: isSearchWeb,
            },
          },
        })
      )
    }
  }, [
    chatBotSettings,
    chatId,
    messages,
    historyIsVisible,
    message,
    language,
    isAutoAudio,
    isSearchWeb,
    dispatchAction,
    params.botName,
    widget,
    visibilityOptionCheck,
  ])

  //show history only if user is creator of chat bot
  const userCanAccessHistory =
    showUserHistory || userInfo?.account_id === chatBotSettings?.account_id

  useEffect(() => {
    const updatePopularity = async () => {
      try {
        axios.post(`/api/chat-bot/library/stats/${chatBotSettings.id}`)
      } catch (err) {
        context?.handleError(err)
      }
    }

    if (
      chatBotSettings &&
      chatBotSettings.id &&
      visibilityOptionCheck &&
      !widget &&
      isFromLibrary
    ) {
      updatePopularity()
    }
  }, [chatBotSettings, widget, visibilityOptionCheck, isFromLibrary])

  useEffect(() => {
    if (
      chatBotSettings &&
      chatBotSettings.isStartWithAudio &&
      chatBotSettings.intro &&
      (isFromLibrary ||
        chatBotSettings?.settings?.publishing?.public ||
        chatBotSettings?.settings?.activeOnEleo)
    ) {
      context?.modal?.show({
        children: (
          <div>
            <p className=' text-brand-violet-tertiary  px-[24px]  text-left text-[14px] font-medium'>
              <T keyName='eleo-tts-content-modal-text'>This chatbot want to use audio</T>
            </p>
            <div className='mb-[14px] mt-[21px] h-[1px] w-full bg-[#5F5FBA]/20' />
            <div className='flex gap-[10px] px-[24px]'>
              <Button
                onClick={() => context?.modal?.hide()}
                className=' group !h-[40px] !w-1/2 !rounded-[4px] !bg-[#5F5FBA]/10  !p-[12px] transition-all duration-300 hover:!bg-[#5F5FBA]/20'
              >
                <p className='text-brand-violet-secondary whitespace-nowrap px-[3px] text-[12px] font-medium uppercase  leading-[1.2em]'>
                  <T keyName='eleo-select-option-disagree'></T>
                </p>
              </Button>
              <Button
                onClick={() => {
                  handleTTS(chatBotSettings.intro, 0)
                  setIsAutoAudio(true)
                  context?.modal?.hide()
                }}
                className='bg-brand-violet-tertiary hover:!bg-brand-violet-secondary !h-[40px] !w-1/2 !rounded-[4px] !p-[12px] transition-all duration-300'
              >
                <p className='whitespace-nowrap px-[3px] text-[12px] font-medium uppercase leading-[1.2em] text-white'>
                  <T keyName='eleo-select-option-agree'></T>
                </p>
              </Button>
            </div>
          </div>
        ),
        modalCardClassNames: '!bg-brand-grey !px-[0px] !pt-[21px] !pb-[14px]',
        closeButtonClassNames: '!w-[18px] !h-[18px] !right-[20px] !top-[22px]',
      })
    }
  }, [chatBotSettings])

  const handleChat = useCallback(
    async (e, question, newChat = false) => {
      e?.preventDefault()

      if (!message?.length && !question?.length) {
        return
      }

      let newChatLog
      if (question && newChat) newChatLog = [{ role: 'user', content: question }]
      else {
        const contextFileInfo = documentContext?.docContext?.map((item) => {
          return {
            name: item.label,
            fileType: item.type,
          }
        })
        const newMessage = {
          role: 'user',
          content: question ?? message,
          attachedFileInfo: contextFileInfo,
          id: uuidv4(),
        }
        newChatLog = [...messages, { ...newMessage, context: documentContext?.docContext }]
      }

      setMessage('')
      setMessages(newChatLog)

      let formData = new FormData()
      formData.append('messages', JSON.stringify(newChatLog))
      formData.append('language', language ?? chatBotSettings.language)
      if (chatBotSettings.modelId) formData.append('model', chatBotSettings.modelId)
      formData.append('name', transformDashToSpace(chatBotSettings.name))
      if (chatBotSettings.monthlyLimit)
        formData.append('monthlyLimit', chatBotSettings.monthlyLimit)
      formData.append('responseLength', chatBotSettings.responseLength)
      formData.append('stories', JSON.stringify(chatBotSettings.stories))
      if (chatBotSettings.tone) formData.append('tone', chatBotSettings.tone)
      formData.append('account_id', chatBotSettings.account_id)
      formData.append('requester_account_id', userAccountId)
      formData.append('trackInteraction', isIntendedForGroups)
      formData.append('id', chatBotSettings.id)
      formData.append('prompt', question ?? message)
      if (isSearchWeb) formData.append('isSearchWeb', isSearchWeb)

      if (chatId) formData.append('chatId', chatId)
      formData.append('kwargs', JSON.stringify({ stream: true }))
      formData = await addContextToFormData(formData, [...documentContext.docContext])

      try {
        setIsGenerating(true)

        const usesContext = documentContext.docContext?.length || isSearchWeb
        setMessages((prevChatLog) => [
          ...prevChatLog,
          { role: 'assistant', content: usesContext ? 'STATE_LOADING_CONTEXT' : 'STATE_LOADING' },
        ])
        const completion = await fetchCompletion(url, formData)

        // If completion hasn't started and the user aborted, remove the placeholder
        setTimeout(() => {
          if (!completion?.length)
            return setMessages((prev) =>
              prev.filter((message, index) => index !== prev.length - 1 || message.image)
            )
        }, 500)

        const pages = Math.max(1, Math.ceil(messages.length / 10))
        if (userCanAccessHistory) setTimeout(() => getChatBotHistory(pages), 500)

        if (isAutoAudio) handleTTS(completion, messages.length + 1)
      } catch (err) {
        const limitErrorMessage = {
          role: 'assistant',
          content: t(
            'eleo-chat-limit-error-primary',
            "Oops! It looks like I've run out of words for the moment... Try asking me later."
          ),
        }
        const errorMessage = {
          role: 'assistant',
          content: t(
            'eleo-chat-error-primary',
            "I'm not feeling quite well... Try asking me later."
          ),
        }

        if (err.name === 'LimitError') {
          setMessages((prevChatLog) => {
            const newChatLog = [...prevChatLog]
            newChatLog[newChatLog.length - 1] = limitErrorMessage
            return newChatLog
          })
        } else {
          setMessages((prevChatLog) => {
            const newChatLog = [...prevChatLog]
            newChatLog[newChatLog.length - 1] = errorMessage
            return newChatLog
          })
        }
      } finally {
        setIsGenerating(false)
      }
    },
    [chatBotSettings, chatId, fetchCompletion, language, message, messages, t]
  )

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      handleChat()
    }
  }

  const getChatBotHistory = useCallback(
    async (pages = 1) => {
      try {
        setHistoryIsLoading(true)
        const response = await axios.get(`/api/chat-bot/histories?pages=${pages}`, {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
          params: {
            chatBotId: chatBotSettings.id,
          },
        })
        const data = response.data
        const reversedChatHistoryItems = data.map((historyItem) => {
          return {
            ...historyItem,
            history: historyItem.history.reverse(),
          }
        })

        const historyData = reversedChatHistoryItems.map((item) => ({
          id: item.chatId,
          question: item.question,
          answer: item.answer,
          date_created: item.date_created,
          words: item.wordLength,
          history: item.history,
        }))

        setChatBotHistory(historyData)
      } catch (err) {
        console.error(err)
      } finally {
        setHistoryIsLoading(false)
      }
    },
    [userInfo?.token, chatBotSettings.id]
  )

  const handleTTS = async (text, index) => {
    setAudioStream()
    if (index === ttsIndex) {
      setTtsIndex()
      return
    }

    setTtsIndex(index)
    const data = {
      input: text,
      language: languages.find((lang) => lang.value === language)?.code,
    }
    if (chatBotSettings.voice) data.voiceId = chatBotSettings.voice

    const res = await fetch(axios.defaults.baseURL + '/api/chat-bot/tts', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: axios.defaults.headers.common['Authorization'],
      },
    })

    if (!res.ok) {
      const data = await res.json()
      if (data.code === 'TEXT_TOO_LONG')
        return errorToast(
          <T keyName='eleo-error-tts-tool-long'>This message is too long. Try a shorter message.</T>
        )
      return errorToast(
        <T keyName='eleo-error-tts'>Text to speech is currently unavailable. Try again later.</T>
      )
    }

    setAudioStream(res.body)
  }

  const handleStopRequest = () => {
    if (abortController) {
      // Abort the request by aborting the controller
      abortController.abort()
    }
  }

  useEffect(() => {
    if (!completionState.message) return
    setMessages((prevChatLog) => {
      const newChatLog = [...prevChatLog]
      newChatLog[newChatLog.length - 1] = {
        role: 'assistant',
        content: completionState.message,
      }
      return newChatLog
    })
  }, [completionState.message])

  useEffect(() => {
    const cmd = completionState.command
    if (cmd?.chatId) setChatId(cmd.chatId)
    if (cmd?.toolResult)
      setMessages((prevChatLog) => {
        const newChatLog = [...prevChatLog]
        const lastMessage = { ...newChatLog[newChatLog.length - 1] }
        lastMessage.image = cmd.toolResult
        if (
          lastMessage.content === 'STATE_LOADING' ||
          lastMessage.content === 'STATE_LOADING_CONTEXT'
        )
          lastMessage.content = ''
        newChatLog[newChatLog.length - 1] = lastMessage
        return newChatLog
      })
  }, [completionState.command])

  useEffect(() => {
    const getChatBotInfo = async () => {
      setSettingsLoading(true)
      try {
        const response = await axios.get(
          `/api/chat-bot/get/${isFromLibrary ? 'forum' : 'public'}/${params.botName || chatBotName}?isWidget=${widget ? 1 : 0}`
        )
        const chatBot = response.data
        setChatBotSettings(chatBot)
        if (!storeFormData?.language) setLanguage(chatBot.language)
        if (!storeFormData?.isSearchWeb) setSearchWeb(chatBot.isStartWithWebSearch)
      } catch (err) {
        console.error(err)
      } finally {
        setSettingsLoading(false)
      }
    }

    getChatBotInfo()
  }, [chatBotName, params.botName])

  //here goes logic for user groups
  useEffect(() => {
    //if at least one group is attached to this chatbot
    // handle validation
    if (isIntendedForGroups && isFromLibrary) {
      checkUserEligibilityToUseChatBot()
    }
  }, [chatBotSettings])

  // Load form data
  useEffect(() => {
    if (!chatBotSettings) return

    const message = searchParams.get('question')
    if (message) {
      handleChat(null, message, true)
      setSearchParams('', { replace: true })
    }
  }, [searchParams, setSearchParams, chatBotSettings, handleChat])

  // useEffect(() => {
  //   setLanguage(chatBotSettings.language)
  // }, [chatBotSettings])

  useEffect(() => {
    if (chatBotSettings?.id && userCanAccessHistory) {
      getChatBotHistory(1)
    }
  }, [chatBotSettings, getChatBotHistory, userCanAccessHistory])

  if (settingsLoading) return <Loader />
  if (!chatBotSettings?.id) return <ChatNotActive />

  return (
    <div className='flex h-full flex-col'>
      {(!isFromLibrary &&
        !chatBotSettings?.settings?.activeOnEleo &&
        !chatBotSettings?.settings?.publishing?.public &&
        !widget) ||
      !isUserAllowedToInteractWithBot ? (
        <ChatNotActive />
      ) : (
        <>
          {!widget && showHeader && (
            <nav className={classNames('z-[90] w-full bg-[#ffffff]  py-4 shadow-lg')}>
              <div
                className={classNames(
                  'flex h-[3.75rem] w-36 shrink-0 items-center justify-center lg:w-[12.5rem]'
                )}
              >
                <a href={getLandingUrl()}>
                  <img className='h-8 w-auto' src={eleoLogo} alt='Company Logo' />
                </a>
              </div>
            </nav>
          )}
          <div
            className={classNames('flex min-h-0 flex-1', {
              hidden: !visible,
            })}
          >
            {historyIsVisible && (
              <ChatBotHistory
                chatBotHistoryData={chatBotHistory}
                historyIsLoading={historyIsLoading}
                setMessages={setMessages}
                intro={chatBotSettings?.intro}
                chatBotId={chatBotSettings?.id}
                chatId={chatId}
                setChatId={setChatId}
                setLanguage={setLanguage}
                widget={widget}
                language={language}
                userCanAccessHistory={userCanAccessHistory}
                setHistoryIsVisible={setHistoryIsVisible}
                setChatIsActive={setIsVisible}
                setShowHistory={setHistoryIsVisible}
                setChatBotHistoryData={setChatBotHistory}
                setAudioStream={setAudioStream}
              />
            )}

            {widget && (
              <ShrinkedChat
                setChatIsActive={setIsVisible}
                chatIcon={chatBotSettings?.settings?.chatIcon || chatBotSettings?.avatar}
                introText={chatBotSettings?.intro}
                setChatIconSizes={setChatIconSizes}
                visible={visible}
              />
            )}

            <ChatBotUi
              chatBotSettings={chatBotSettings}
              setMessage={setMessage}
              handleKeyDown={handleKeyDown}
              message={message}
              messages={messages}
              setMessages={setMessages}
              handleChat={handleChat}
              setChatId={setChatId}
              setLanguage={setLanguage}
              widget={widget}
              language={language}
              userCanAccessHistory={userCanAccessHistory}
              setHistoryIsVisible={setHistoryIsVisible}
              setChatIsActive={setIsVisible}
              handleTTS={handleTTS}
              isAutoAudio={isAutoAudio}
              setIsAutoAudio={setIsAutoAudio}
              setAudioStream={setAudioStream}
              avatar={userInfo?.avatar}
              isGenerating={isGenerating}
              handleStopRequest={handleStopRequest}
              audioStream={audioStream}
              ttsIndex={ttsIndex}
              setTtsIndex={setTtsIndex}
              isSearchWeb={isSearchWeb}
              setSearchWeb={setSearchWeb}
              documentContext={documentContext}
              isFromLibrary={isFromLibrary}
            />
          </div>

          {widget && (
            <ShrinkedChat
              setChatIsActive={setIsVisible}
              chatIcon={chatBotSettings?.settings?.chatIcon || chatBotSettings?.avatar}
              introText={chatBotSettings?.intro}
              setChatIconSizes={setChatIconSizes}
              visible={visible}
            />
          )}

          <AudioPlayer
            streamSource={audioStream}
            setSource={setAudioStream}
            callback={setTtsIndex}
          />
        </>
      )}
    </div>
  )
}

function ShrinkedChat({ setChatIsActive, chatIcon, introText, visible, setChatIconSizes }) {
  const chatIconRef = useRef(null)

  return (
    <div
      className={classNames({
        'fixed bottom-0 z-[9999999] w-auto overflow-hidden': !visible,
        hidden: visible,
      })}
    >
      {chatIcon ? (
        <div
          className='cursor-pointer'
          onClick={() => setChatIsActive(true)}
          data-tooltip-id={`chat-tooltip`}
        >
          <div className='flex items-center justify-center'>
            <img
              className='h-auto max-w-full'
              src={chatIcon}
              alt='icon'
              ref={chatIconRef}
              onLoad={() =>
                setChatIconSizes({
                  width: chatIconRef.current.naturalWidth,
                  height: chatIconRef.current.naturalHeight,
                })
              }
            />
          </div>
        </div>
      ) : (
        <div
          className='w-full cursor-pointer'
          onClick={() => setChatIsActive(true)}
          data-tooltip-id={`chat-tooltip`}
        >
          <div className='flex w-full items-center justify-end'>
            <img
              className='size-[50px] h-auto max-w-full'
              src={defaultAvatar}
              onLoad={() =>
                setChatIconSizes({
                  width: 50,
                  height: 50,
                })
              }
              alt='eleo logo'
            />
          </div>
        </div>
      )}
      <Tooltip id={`chat-tooltip`} className='!bg-brand-violet shadow-md'>
        <ul className='flex max-w-[250px] flex-col gap-[10px]'>
          <li className='flex flex-col text-[14px] leading-[1.2]'>
            <b>{introText || 'How I Can Help You?'}</b>
          </li>
        </ul>
      </Tooltip>
    </div>
  )
}
